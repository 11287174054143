import { $orderNameDetail } from "../../../api/home_marriage_test"

const resultData = {
    data () {
        return {
            data_list:{
                scwg:{
                    self_scwg:{
                        fiveGirl:{},
                        name:''
                    },
                    other_scwg:{
                        fiveGirl:{},
                        name:''
                    }
                },
                fallinmatch:{
                    str:'',
                    score:''
                },
                xg:{
                    self:'',
                    other:''
                },
                fallin_status:{
                    one:'',
                    three:'',
                    two:''
                },
                comego_status:{
                    one:'',
                    three:'',
                    two:''
                },
                future_status:{
                    one:'',
                    three:'',
                    two:''
                },
                money_status:''
                
            },
            score_arr:[
                // 'https://cdn.zhiyileiju.cn/WechatIMG551.png',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
            ],
            score_arr2:[
                // 'https://cdn.zhiyileiju.cn/WechatIMG551.png',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
            ],
            score_arr3:[
                // 'https://cdn.zhiyileiju.cn/WechatIMG551.png',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
            ],
            man_name_number:3,
            girl_name_number:3,
        }
    },
    created () {
        this.orderNameDetailDataRequest()
    },
   
    methods:{
        orderNameDetailDataRequest() {//用户信息接口
            let dataRequest_data_ = {
                order_id: this.$route.params.id,
                name_id: 0,
            }
            $orderNameDetail(dataRequest_data_).then( ( res ) => {//详情数据
                if ( res.status === 200) {
                    console.log('res',res.data.data)
                    this.data_list = res.data.data.namePairData
                    //男女名字个数
                    this.man_name_number = res.data.data.namePairData.scwg.self_scwg.name.length;
                    this.girl_name_number = res.data.data.namePairData.scwg.other_scwg.name.length;
                    for ( let i = 0; i < res.data.data.namePairData.fallin_status.two; i++ ) { //评分1 心
                        this.score_arr.splice(i,1,'https://cdn.zhiyileiju.cn/WechatIMG551.png')
                    }
                    for ( let i = 0; i < res.data.data.namePairData.comego_status.two; i++ ) { //评分2 心
                        this.score_arr2.splice(i,1,'https://cdn.zhiyileiju.cn/WechatIMG551.png')
                    }
                    for ( let i = 0; i < res.data.data.namePairData.future_status.two; i++ ) { //评分3 心
                        this.score_arr3.splice(i,1,'https://cdn.zhiyileiju.cn/WechatIMG551.png')
                    }
                }
                
            })
        }
    }

}

export default resultData