<template>
    <div class="increase">
       <div class="content_box_">
            <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
            <div class="title_bg_text_">双方婚后生活</div>
            <p class="text_" >
                <span class="overstriking_ text_red__">婚姻格言</span> <br>
                1、婚姻就是把爱情落实到生活里，睁开一只眼看清楚对方的优点，闭上一只眼无视对方的缺点。在婚姻中学着做个合适的人，而不是去找个合适的人。<br>
                2、夫妻相处之道是重视及感谢对方所做的一切不要凡事视为当然。<br>
                3、能有智慧建立一个温馨美满家庭的人，才算是一个真正成功的人。<br>
                4、没有100分的另一半，只有50分的两个人。<br>
                5、两个人的幸福需要共同维护才能长久，结果仅供娱乐和参考。
            </p>
            <p class="text_" style="margin-top:0">
            </p>
        </div>
    </div>
</template>

<script>
import resultData from "../../../mixin/nameMatching/result_content/resultData"
export default {
     mixins:[resultData],
}
</script>

<style lang="scss" scoped>
    .increase{
        .content_box_{
            position: relative;
            width: 7.18rem;
            // height: 8.59rem;
            margin: 0 auto;
            background: #fff;
            border-radius: .30rem;
            box-shadow: 0 0 5px #e8c7d6;
            padding-top: .24rem;
            padding-bottom: .6rem;
            margin-bottom: .47rem;
            margin-top: 0.1rem;
            .flower_img_{
                position: absolute;
                width: 7.18rem;
                top: 0;
            }
            .title_bg_text_{
                width: 4.47rem;
                height: .73rem;
                margin: 0 auto;
                text-align: center;
                color: #C4393E;
                font-size: .38rem;
                font-weight: bold;
                line-height: .73rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG544.jpeg') no-repeat;
                background-size: 100% 100%;
            }
            .name_box_{
                position: relative;
                width: 6.27rem;
                height: 1.41rem;
                margin: .55rem auto;
                .grade_box_{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 1.4rem;
                    height: 1.4rem;
                    font-size: .72rem;
                    font-weight: bold;
                    color: #FFA3B2;
                    text-align: center;
                    line-height: 1.4rem;
                    border-radius: 1.4rem;
                    background: #fff;
                }
                .name_box_common{
                    width: 50%;
                    height: 1.41rem;
                    .name_text{
                        font-size: .36rem;
                        font-weight: bold;
                        color: #3D659A;
                        padding-left: .28rem;
                        margin-top: .35rem;
                        p{
                            // font-size: .18rem;
                            font-size: .23rem;
                            font-weight: 400;
                            // margin-top: .18rem;
                        }
                    }
                }
                .name_left_box{
                    float: left;
                    border-top-left-radius: .1rem;
                    border-bottom-left-radius: .1rem;
                    background: #C7DFFF;
                }
                .name_right_box{
                    float: right;
                    border-top-right-radius: .1rem;
                    border-bottom-right-radius: .1rem;
                    background: #FECCDD;
                    .name_text{
                        text-align: right;
                        padding-right: .28rem;
                        color: #943858;
                    }
                }
            }
            .text_{
                padding: 0 .5rem;
                font-size: .28rem;
                color: #525252;
                line-height: .48rem;margin-top: .35rem;
            }
            .text_:last-child{
                margin-top: .37rem;
                color: #67386F;
            }
            .overstriking_{
                font-weight: bold;
            }
            .score_box_{
                display: inline-block;
                .star_box_{
                    display: inline-block;
                    width: .34rem;
                    margin-right: .08rem;
                }
            }
            .text_red__{
                color: #C4393E;
            }
            
            .title_text__{
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #C4393E;
                text-align: center;
                margin-top: .36rem;
                margin-bottom: .28rem;
            }
            
            .user_name_text_{
                padding-left: .37rem;
                font-size: .32rem;
                font-weight: bold;
                color: #525252;
                 margin-top: .86rem;
            }
            .user_name_text_:last-child{
                margin-top: 0;
            }
            .img_geomantic_box{
                position: relative;
                height: 2.6rem;
                margin-top: .6rem;
                .div_text_box{
                    width: 1.2rem;
                    // display: inline-block;
                    position: absolute;
                    height: .49rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .49rem;
                }
                    .diamond_box{
                        display: inline-block;
                        width: .49rem;
                        height: .49rem;
                        line-height: .49rem;
                        text-align: center;
                        color: #fff;
                        background: #D74A50;
                    }
                .big_box_{
                    position: absolute;
                    width: 2rem;
                    height: .88rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .88rem;
                    box-sizing: border-box;
                    border-left: .05rem solid #D74A50;
                    padding-left: .59rem;
                    .across_{
                        position: absolute;
                        top: 50%;
                        left: -.001rem;
                        transform: translate(0,-50%);
                        width: .44rem;
                        height: .05rem;
                        background: #D74A50;

                    }
                }
                .center_box__{
                    position: absolute;
                    left: 1.8rem;
                }
                .right_box__{
                    position: absolute;
                    right: 3.3rem;
                    .img_box__{
                        position: absolute;
                        left: 1.45rem;
                        top: .4rem;
                        width: .35rem;
                    }
                    
                }
            }
            .gossip_bg_box_{
                width: 7.18rem;
                height: 1.52rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG547.jpeg') no-repeat;
                background-size: 100% 100%;
                margin-top: .52rem;
                margin-bottom: .46rem;
                    text-align: center;
                .name_text__{
                    color: #525252;
                    font-size: .36rem;
                    margin-top: .84rem;
                    line-height: .35rem;
                    width: 1.7rem;
                    .designation__{
                        display: inline-block;
                        color: #9C9C9C;
                        font-size: .23rem;
                        // transform: scale(1.4);
                    }
                }
                .man_name_{
                    float: left;
                    margin-left: .4rem;
                }
                .girl_name_{
                    float: right;
                     margin-right: .4rem;
                }
            }
            
        }
    }
</style>