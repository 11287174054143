import request from "../utils/request.js";

export const $addOrder = (params) => { //首页提交用户信息
    return request({
        url:'/addOrder',
        method:'post',
        data:params
    })
}

export const $ekaf = (params) => {//获取用户动态列表，评论
    return request({
        url:'/ekaf',
        method:'post',
        data:params,
        
        
    })
}

export const $order = (data) => {//获取用户信息
    return request({
        url:`/order/${data}`,
        method:'get',
        
        // params:data
    })
}

export const $getSalesPrice = (data) => {//价格获取
    return request({
        url:`/getSalesPrice`,
        method:'get',
        params:data,
    })
}

export const $orderConfirm = (params) => { //支付接口
    return request({
        url:'/order/confirm',
        // url:'/https://api.zhiyileiju.cn/api/v1/order/confirm',
        method:'post',
        data:params
    })
}
export const $paymentWechatconf = (params) => { //weix
    return request({
        url:'/payment/wechatconf',
        // url:'/https://api.zhiyileiju.cn/api/v1/order/confirm',
        method:'post',
        data:params
    })
}
export const $paymentQuery = (params) => { //支付接口
    return request({
        url:'/payment/query',
        method:'post',
        data:params
    })
}

export const $payment = (data,data2) => {//微信支付接口
    return request({
        // url:`/payment/${data}/${data2}/wechat`,
        url:`/payment/wechathy/${data2}/${data}/wechat`,
        // 
        // url:`https://api.zhiyileiju.cn/api/v1/payment/${data}/${data2}/wechat`,
        method:'get',
        // params:data
    })
}

export const $payment_zfb = (data,data2) => {//浏览器支付接口
    return request({
        url:`/payment/${data}/${data2}`,
        // url:`https://api.zhiyileiju.cn/api/v1/payment/${data}/${data2}`,
        method:'get',
        // params:data
    })
}

export const $orderNameDetail = (params) => {//付款后结果页面数据接口
    return request({
        url:'/orderNameDetail',
        method:'post',
        data:params
    })
}
export const $orderBindIphone = (params) => {//提交手机号码
    return request({
        url:'/order/bindIphone',
        method:'post',
        data:params
    })
}
export const $getResultOrderId = (params) => {//姓名查询
    return request({
        url:'/getResultOrderId',
        method:'post',
        data:params
    })
}

export const $payRiverOrder = (params) => {//获取用户id
    return request({
        url:'/payRiverOrder',
        method:'post',
        data:params
    })
}
export const $paymentQueryRiver = (params) => {//获取用户id
    return request({
        url:'/payment/query_river',
        method:'post',
        data:params
    })
}

// export const $orderPPXL = (data) => {
//     return request({
//         url:'/api/order/PPXL',
//         method:'get',
//         params:data
//     })
// }